<template>
  <v-app
    app
    data-iframe-height
		style="height: 100vh !important;"
  >
    <v-main
      :style="$vuetify.breakpoint.width >= 960 ? 'display: grid; align-items: center; background-color:' + appStyle.backgroundColor : appStyle"
    >
      <div
        id="version"
        :version="version"
      />
      <Item />
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';
import Item from './components/Item';
import { version } from '../package.json'

export const EventBus = new Vue();

export default {
	name: 'App',
	components: {
		Item
	},
	data: () => ({
		organization_name: '',
		appStyle: {
			backgroundColor: ''
		}
	}),
	created() {
		EventBus.$on('organization', function(organization) {
			this.organization_name = organization.label
			this.appStyle.backgroundColor = organization.primary_color
			let script = document.createElement('script')
			script.src = 'https://europe-west1-salescloud.cloudfunctions.net/renderSalesCloudAppJS?organization=' + organization.uuid
			document.head.appendChild(script)
		}.bind(this))

		// Random change for test

		const urlParams = new URLSearchParams(window.location.search)
		const dark = urlParams.get('dark')

		if(dark === 'true') {
			this.$vuetify.theme.dark = true
		}

    const time = urlParams.get('time')

    if((typeof time === 'undefined' || time === null) && window.location.search.includes('?')) {
      window.location = window.location + '&time=' + Date.now()
    }

    const language = urlParams.get('lang')

    if(language !== null && language !== undefined) {
      this.$i18n.locale = language
    }

	},
  computed: {
    version() {
      return version
    }
  }
};
</script>

<style>
.theme--light.v-application {
  background: transparent !important;
}
</style>
