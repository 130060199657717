import { render, staticRenderFns } from "./UpsellItems.vue?vue&type=template&id=4e563693&scoped=true"
import script from "./UpsellItems.vue?vue&type=script&lang=js"
export * from "./UpsellItems.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e563693",
  null
  
)

export default component.exports