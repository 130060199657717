<template>
  <v-row
    id="item"
    align="center"
    justify="center"
    style="height: 100%"
    no-gutters
  >
    <v-col
      cols="auto"
      style="height: 100%"
    >
      <v-card
        flat
        style="height: 100%;"
        :max-width="showImage ? 1000 : 600"
      >
        <div
          style="height: 100%"
          class="d-flex"
          :class="isMobile ? 'flex-column' : ''"
        >
          <div
            v-if="showImage"
            :style="isMobile ? 'width: 100%' : 'width: 48%'"
          >
            <slot name="image" />
          </div>
          <div class="flex-grow-1 d-flex flex-column justify-space-between">
            <slot name="body" />
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
	name: "Layout",
	props: {
		isMobile: {
			type: Boolean
		},
		showImage: {
			type: Boolean
		}
	}
}
</script>

<style scoped>

</style>
