<template>
  <div class="px-3 pt-2">
    <div
      class="pb-4"
      style="font-weight: 500; font-size: 18px;"
    >
      {{ $t('offerToAddToOrder') }}
    </div>
    <template v-for="item in upsellItems">
      <UpsellItem
        :key="item.uuid"
        :upsell-item="item"
        v-if="showUpsellItem(item)"
        :quantity="quantity"
        :selected-additional-items="selectedAdditionalItems"
        v-on="$listeners"
      />
    </template>
  </div>
</template>

<script>
import UpsellItem from "@/components/UpsellItem"

export default {
  name: "UpsellItems",
  components: {
    UpsellItem
  },
  props: {
    upsellItems: {
      type: Array,
      default: () => []
    },
    quantity: {
      type: Number,
      default: 1
    },
    selectedAdditionalItems: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    upsellItemQuantities(item) {
      let min = 0
      let max = 10

      if(typeof item.minimumOrderQuantity !== 'undefined') {
        min = item.minimumOrderQuantity
      }

      if(typeof item.maximumOrderQuantity !== 'undefined') {
        if(item.maximumOrderQuantity >= min) {
          max = item.maximumOrderQuantity
        }
      }

      return Array.from({ length: (max + 1) - min}, (_,i) => min + i)
    },
    showUpsellItem(item) {
      const upsellItemQuantities = this.upsellItemQuantities(item)
      if(upsellItemQuantities.length === 1) {
        return !upsellItemQuantities[0] === 0
      }
      return true
    }
  }
}
</script>

<style scoped>

</style>
