<template>
  <div>
    <div
      style="font-size: 16px;"
      v-if="payWhatYouWant"
    >
      {{ $t('payWhatYouWant') }}
    </div>
    <div
      style="font-size: 16px;"
      v-if="!payWhatYouWant && type !== 'subscription'"
    >
      {{ $t('total') }}
    </div>
    <div
      style="font-size: 16px;"
      v-if="!payWhatYouWant && type === 'subscription'"
    >
      {{ $t('initialPayment') }}
    </div>
    <div
      style="font-weight: 600; font-size: 28px;"
      v-if="!payWhatYouWant"
    >
      <span>
        {{ currencyFormattedTotal }}
      </span>
      <span v-if="showVat">
        {{ $t('includingVat') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderTotalAmount",
  props: {
    quantity: {
      type: Number,
      default: 1
    },
    price: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: ''
    },
    selectedVariations: {
      type: Array,
      default: () => []
    },
    selectedAdditionalItems: {
      type: Array,
      default: () => []
    },
    selectedVariation: {
      type: String,
      default: null
    },
    showVat: {
      type: Boolean,
      default: true
    },
    payWhatYouWant: {
      type: Boolean,
      default: false
    },
    variations: {
		type: Array,
	    default: null
    }
  },
  computed: {
    currencyFormattedTotal() {
      let amount = 0
      let currency = 'ISK'

      if(typeof this.price !== 'undefined' && this.price !== null) {
        if(typeof this.price.amount !== 'undefined' && typeof this.price.currency_code !== 'undefined') {
          amount = this.price.amount
          currency = this.price.currency_code
        }
      }

      if(this.selectedVariation) {
	    const foundVariation = this.variations.find(variation => variation && variation.uuid === this.selectedVariation)
        if(foundVariation && foundVariation.price && foundVariation.price.amount > 0) {
	        amount = foundVariation.price.amount
        }
      }

      if(!this.selectedVariation && Array.isArray(this.selectedVariations) && this.selectedVariations.length > 0) {
        const variationsAmount = this.selectedVariations.reduce((sum, variation) => {
          if(typeof variation !== 'undefined' && variation !== null) {
            if(typeof variation.sale_price !== 'undefined' && variation.sale_price !== null && typeof variation.sale_price.amount !== 'undefined' && variation.sale_price.amount > 0) {
              return sum + variation.sale_price.amount
            }
            if(typeof variation.price !== 'undefined' && variation.price !== null && typeof variation.price.amount !== 'undefined' && variation.price.amount > 0) {
              return sum + variation.price.amount
            }
          }
        }, 0)
        amount += variationsAmount
      }

      amount = amount * this.quantity

      // We add additional items to the amount after we multiply by quantity because additional item has its own quantity
      if(Array.isArray(this.selectedAdditionalItems) && this.selectedAdditionalItems.length > 0) {
        const additionalItemsTotalAmount = this.selectedAdditionalItems.reduce((sum, additionalItem) => {
          if(typeof additionalItem !== 'undefined' && additionalItem !== null) {
            if(typeof additionalItem.sale_price !== 'undefined' && additionalItem.sale_price !== null && typeof additionalItem.sale_price.amount !== 'undefined' && additionalItem.sale_price.amount > 0) {
              return sum + (additionalItem.sale_price.amount * additionalItem.quantity)
            }
            if(typeof additionalItem.price !== 'undefined' && additionalItem.price !== null && typeof additionalItem.price.amount !== 'undefined' && additionalItem.price.amount > 0) {
              return sum + (additionalItem.price.amount * additionalItem.quantity)
            }
          }
        }, 0)

        amount += additionalItemsTotalAmount
      }

      if(currency === 'ISK') {
        return `${amount} kr.`
      }
      const formatter = Intl.NumberFormat(currency, { style: 'currency', currency: currency })
      const amountInCurrency = amount/100
      return formatter.format(amountInCurrency)
    }
  }
}
</script>

<style scoped>

</style>
