import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.upsellAmountFollowsItemQuantity)?_c(VRow,{attrs:{"align":"center","dense":""}},[_c(VCol,{staticClass:"grow",attrs:{"cols":"auto"}},[_c('div',{staticStyle:{"font-size":"16px","font-weight":"500"}},[_vm._v(" "+_vm._s(`${_vm.upsellItem.title}`)+" ")]),_c('div',[_vm._v(" "+_vm._s(`${_vm.upsellTotalItemAmount} (${_vm.quantity}x ${_vm.upsellUnitItemAmount})`)+" ")])]),_c(VCol,{staticClass:"shrink",attrs:{"cols":"auto"}},[_c(VSwitch,{attrs:{"color":"success"},model:{value:(_vm.toggleUpsellQuantity),callback:function ($$v) {_vm.toggleUpsellQuantity=$$v},expression:"toggleUpsellQuantity"}})],1)],1):_c(VRow,{staticClass:"mb-4",attrs:{"dense":"","align":"center"}},[_c(VCol,{staticStyle:{"font-size":"16px","font-weight":"500"},attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.upsellItem.title)+" ")]),_c(VCol,{staticClass:"grow",attrs:{"cols":"auto"}},[_c(VSelect,{attrs:{"hide-details":"","outlined":"","items":_vm.upsellItemQuantities,"dense":""},model:{value:(_vm.upsellQuantity),callback:function ($$v) {_vm.upsellQuantity=$$v},expression:"upsellQuantity"}})],1),_c(VCol,{staticClass:"shrink",attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"py-5",attrs:{"elevation":"0","small":"","disabled":!_vm.canDecrease,"color":"white"},on:{"click":_vm.decreaseQuantity}},[_c(VIcon,[_vm._v("mdi-minus")])],1)],1),_c(VCol,{staticClass:"shrink",attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"py-5",attrs:{"elevation":"0","small":"","disabled":!_vm.canIncrease,"color":"white"},on:{"click":_vm.increaseQuantity}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }