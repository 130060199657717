<template>
  <v-container
    class="pa-0 my-0 mx-auto"
    :style="$vuetify.breakpoint.width < 960 ? 'height: 100%' : ''">
    <v-row align="center" justify="center" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular indeterminate color="primary" />
        <div class="regular mt-3">
          {{ message }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="!loading && item === null" align="center" justify="center">
      <v-col cols="12">
        <v-alert prominent type="error">
          <v-row align="center" justify="center">
            <v-col class="grow text-center">
              <div>{{ message }}</div>
              <div class="caption">
                {{ subMessage }}
              </div>
            </v-col>
            <v-col class="shrink">
              <v-btn @click="fetchItem(itemUUID, organizationUUID)">
                Try again
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>

    <!--    <v-row-->
    <!--      no-gutters-->
    <!--      class="pb-16"-->
    <!--      justify="center"-->
    <!--      v-if="logo !== null && $vuetify.breakpoint.width >= 960"-->
    <!--    >-->
    <!--      <v-col cols="auto">-->
    <!--        <v-img-->
    <!--          :src="logo"-->
    <!--          max-width="150"-->
    <!--          max-height="80"-->
    <!--        />-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <Layout
      v-if="!loading && item"
      :is-mobile="$vuetify.breakpoint.width < 960"
      :show-image="item.images && item.images[0] && !hideImage">
      <template v-slot:image>
        <v-img
          v-if="item.images && item.images[0] && !hideImage"
          :src="item.images[0].file.sources.original"
          :height="$vuetify.breakpoint.width < 960 ? 200 : '100%'">
          <v-overlay absolute v-if="isSoldOut" opacity="0.8">
            <v-chip large class="text-uppercase font-weight-bold" color="red">
              {{ $t("item.soldOut") }}
            </v-chip>
          </v-overlay>
        </v-img>
      </template>
      <template v-slot:body>
        <div>
          <v-card-title
            class="headline text-break px-6 pt-6 pb-2"
            style="font-size: 28px !important; color: #0b0e16 !important">
            {{ translateByType("item.title", item) }}
          </v-card-title>
          <v-btn
            v-if="translateByType('item.description.value', item) !== null"
            class="ml-2 text-none"
            color="primary"
            text
            @click="showDescription = !showDescription">
            {{ $t("more") }}
          </v-btn>

          <v-card-text v-if="saleNotAvailable" class="px-6">
            <v-alert
              type="info"
              v-if="saleHasNotStarted"
              border="left"
              text
              class="mb-0">
              {{ $t("item.saleNotStarted") }}
            </v-alert>
            <v-alert
              type="info"
              v-if="saleEnded"
              border="left"
              text
              class="mb-0">
              {{ $t("item.saleEnded") }}
            </v-alert>
            <v-alert
              type="error"
              v-if="isSoldOut && !saleHasNotStarted && !saleEnded"
              border="left"
              text
              class="mb-0">
              {{ $t("item.soldOutText") }}
            </v-alert>
          </v-card-text>

          <v-card-text
            v-if="allowQuantitySelection && !item.payWhatYouWant"
            class="px-6">
            <div class="mb-4" style="font-size: 20px">
              {{ $t("item.chooseQuantity") }}
            </div>

            <v-text-field
              v-model="quantity"
              outlined
              hide-details
              type="number"
              :min="minValue"
              :max="maxValue" />
            <v-alert
              type="error"
              v-if="quantity < 1"
              border="left"
              text
              class="mb-0 mt-1">
              {{ $t("item.minimumWarning") }}
            </v-alert>
          </v-card-text>
          <v-card-text
            v-if="
              quantityRange.length > 1 &&
              !saleNotAvailable &&
              !allowQuantitySelection &&
              !item.payWhatYouWant
            "
            class="px-6">
            <div
              v-if="
                unitOfMeasure !== null &&
                organizationUUID === '7dbcf598-dc8d-490b-8733-931deb49e967'
              "
              class="mb-4"
              style="font-size: 20px">
              {{ unitOfMeasure }}
            </div>
            <div v-else class="mb-4" style="font-size: 20px">
              {{ $t("item.quantity") }}
            </div>

            <v-select
              :items="quantityRange"
              v-model="quantity"
              hide-details
              outlined />
          </v-card-text>

          <v-divider
            v-if="
              item && Array.isArray(upsellItems) && upsellItems.length > 0
            " />
          <v-card-text
            v-if="
              item &&
              Array.isArray(upsellItems) &&
              upsellItems.length > 0 &&
              showUpsell
            ">
            <UpsellItems
              :upsell-items="upsellItems"
              :quantity="quantity"
              :selected-additional-items="selectedAdditionalItems"
              @addUpsellItem="addUpsellItem"
              @removeUpsellItem="removeUpsellItem" />
          </v-card-text>
          <v-divider
            v-if="
              item &&
              Array.isArray(item.additionalItems) &&
              item.additionalItems.length > 0 &&
              item.payWhatYouWant
            " />

          <v-card-text v-if="item.payWhatYouWant">
            <v-text-field
              type="number"
              :label="$t('item.amount')"
              outlined
              v-model="customAmount"
              :suffix="item.price.currency_code" />
          </v-card-text>

          <!--          <v-divider v-if="showTotalAmount && !saleNotAvailable" />-->
          <!--          <v-divider />-->

          <v-card-actions
            v-if="variationOptions.length > 0 && !saleNotAvailable"
            class="px-6">
            <v-select
              hide-details
              :items="variationOptions"
              :label="$t('item.ticketType')"
              style="width: 100%"
              v-model="selectedVariation"
              item-text="label"
              item-value="uuid"
              required
              solo />
          </v-card-actions>
        </div>
        <div>
          <v-card-actions
            class="px-6 pb-6 pt-12"
            :class="
              itemPrice.amount > 0 ? 'justify-space-between' : 'justify-end'
            ">
            <!--            <div v-if="!item.payWhatYouWant">-->
            <!--              <span-->
            <!--                class="red&#45;&#45;text"-->
            <!--                style="font-weight: bolder; text-decoration: line-through;"-->
            <!--                v-if="hasSalePrice"-->
            <!--              > {{ item.price | toCurrency }} </span>-->
            <!--              <span-->
            <!--                class="text-h6"-->
            <!--                style="font-weight: bolder;"-->
            <!--              >-->
            <!--                <span>-->
            <!--                  {{ itemPrice | toCurrency }}-->
            <!--                </span>-->
            <!--                <span v-if="showVat">-->
            <!--                  {{ $t('includingVat') }}-->
            <!--                </span>-->
            <!--              </span>-->
            <!--            </div>-->
            <OrderTotalAmount
              v-if="
                showTotalAmount && !saleNotAvailable && itemPrice.amount > 0
              "
              :quantity="
                typeof quantity === 'number' ? quantity : parseFloat(quantity)
              "
              :price="itemPrice"
              :type="item.type"
              :selected-variations="selectedVariations"
              :selected-additional-items="selectedAdditionalItems"
              :selected-variation="selectedVariation"
              :show-vat="showVat"
              :pay-what-you-want="item.payWhatYouWant"
              :variations="variationOptions"
            />
            <v-btn
              data-testid="button-continue-item"
              @click="addToCart"
              x-large
              class="text-none px-10 ml-2"
              color="primary"
              depressed
              :disabled="
                addingToCart ||
                item.availability.current <= 0 ||
                variationRequired() ||
                saleNotAvailable ||
                quantity < 1
              "
              :loading="addingToCart">
              <!--              {{ $t('item.buyNow') }}-->
              {{ $t("continue") }}
            </v-btn>
          </v-card-actions>
        </div>
      </template>
    </Layout>
    <v-row
      v-if="$vuetify.breakpoint.width >= 960"
      align="center"
      justify="center"
      class="text-center pt-16"
      no-gutters>
      <v-col cols="12">
        <div style="font-size: 14px" class="white--text">
          Powered by
          <a
            target="_blank"
            href="https://www.salescloud.is"
            class="white--text"
            style="font-weight: 600"
            rel="noopener noreferrer"
            >SalesCloud</a
          >
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showDescription" max-width="500">
      <v-card class="pa-4">
        <v-card-title
          class="headline text-break pa-0 pb-3"
          style="font-size: 28px !important; color: #0b0e16 !important">
          {{ translateByType("item.title", item) }}
        </v-card-title>
        <v-card-text
          class="pa-0"
          v-html="translateByType('item.description.value', item)" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from "../App.vue";
import axios from "axios";
import "url-search-params-polyfill";
import jsesc from "jsesc";
import i18n from "../i18n.js";

import UpsellItems from "@/components/UpsellItems";
import OrderTotalAmount from "@/components/OrderTotalAmount";
import Layout from "@/components/Layout";

export default {
  components: { OrderTotalAmount, UpsellItems, Layout },
  name: "Item",
  data: () => ({
    graphUrl: "https://graph.salescloud.is?",
    // graphUrl: 'http://localhost:8080/?',
    quantityRange: [1],
    useQuantityRangeFromParams: false,
    loading: true,
    itemUUID: null,
    message: "",
    subMessage: "",
    item: null,
    addingToCart: false,
    sessionToken: null,
    quantity: 1,
    channelUUID: null,
    categoryUUID: null,
    selectedItemInCategory: null,
    language: null,
    variationOptions: [],
    selectedVariation: null,
    supportedLanguages: ["is", "en"],
    systemTimeInMillis: null,
    customAmount: null,
    organization: null,
    currentItemBookingsCount: 0,
    selectedTimeSlots: [],
    organizationUUID: null,
    selectedAdditionalItems: [],
    selectedVariations: [],
    showTotalAmount: true,
    hideImage: false,
    translations: [],
    showUpsell: true,
    showVat: true,
    showDescription: false,
    allowQuantitySelection: false,
  }),
  /**
   * @property endDate
   * @property startDate
   * @property logo
   * @property availability
   */
  computed: {
    minValue() {
      if (this.item && this.item.minimumOrderQuantity) {
        return parseFloat(this.item.minimumOrderQuantity);
      }

      return 1;
    },
    maxValue() {
      if (this.item && this.item.maximumOrderQuantity) {
        return parseFloat(this.item.maximumOrderQuantity);
      }
      return 5000;
    },
    logo() {
      if (this.organization === null) {
        return null;
      }
      if (
        typeof this.organization.logo === "undefined" ||
        this.organization.logo === null
      ) {
        return null;
      }
      if (
        typeof this.organization.logo.file === "undefined" ||
        this.organization.logo.file === null
      ) {
        return null;
      }
      if (
        typeof this.organization.logo.file.sources === "undefined" ||
        this.organization.logo.file.sources === null
      ) {
        return null;
      }
      return this.organization.logo.file.sources.original;
    },
    upsellItems() {
      if (typeof this.item !== "undefined" && this.item !== null) {
        if (
          Array.isArray(this.item.additionalItems) &&
          this.item.additionalItems.length > 0
        ) {
          return this.item.additionalItems.filter(
            (i) =>
              (i &&
                i.minimumOrderQuantity !== null &&
                i.maximumOrderQuantity === null) ||
              (i.minimumOrderQuantity === null &&
                i.maximumOrderQuantity !== null)
          );
        }
      }
      return [];
    },
    customPrimaryColor() {
      return new URLSearchParams(window.location.search).get("primaryColor");
    },
    isSoldOut() {
      return (
        this.item &&
        this.item.availability &&
        this.item.availability.current <= 0
      );
    },
    hasSalePrice() {
      return(
        this.item.sale_price !== null &&
        this.item.sale_price.amount !== null
      );
    },
    itemPrice() {
      //Utanskólasala menntaskóla: NFMH, NFVÍ Ball, NFVÍ leikrit, NFF, Skólafélag MS, Skólafélag MR, Framtíðin
      if (
        this.item.event_guest_price !== null &&
        typeof this.item.event_guest_price !== "undefined" &&
        this.item.event_guest_price.amount !== null &&
        typeof this.item.event_guest_price.amount !== "undefined" &&
        this.item.event_guest_price.amount !== 0 &&
        (this.channelUUID === "b2eb7dd7-255b-4716-82e5-867abbf55417" ||
          this.channelUUID === "9ef8f379-4810-4b03-b042-14535b751577" ||
          this.channelUUID === "55286c91-0424-4f07-9829-cb725cecd012" ||
          this.channelUUID === "62746ce1-b0b6-4718-bafe-04b8a25ec606" ||
          this.channelUUID === "a466595a-6cdb-44bc-9f1f-5a73cc6a1930" ||
          this.channelUUID === "f5c998b7-86f1-4b28-aba1-ff96ea2fe666" ||
          this.channelUUID === "f6120359-d34d-483d-8ee1-43848f4426ed")
      ) {
        return this.item.event_guest_price;
      }

      if (
        this.item.sale_price !== null &&
        this.item.sale_price.amount !== null
      ) {
        return this.item.sale_price;
      }

      return this.item.price;
    },
    unitOfMeasure() {
      if (typeof this.item === "undefined" || this.item === null) {
        return null;
      }
      if (
        typeof this.item.unitOfMeasure === "undefined" ||
        this.item.unitOfMeasure === null
      ) {
        return null;
      }
      return (
        this.item.unitOfMeasure.charAt(0).toUpperCase() +
        this.item.unitOfMeasure.slice(1)
      );
    },
    useNewCheckout() {
      // if(this.channelUUID === 'b9991e37-0ec5-4db3-84bc-4c0fce110dbc') { // Janus Heilsuefling
      // 	return false
      // }
      //
      // if(this.channelUUID === '561b38c5-7015-4822-8e1d-29a07d391b3e' || this.channelUUID === '5d84c5d5-ba1a-4caf-9551-b475dd88a95c') { // Sólir Yoga Studio
      // 	return false
      // }
      //
      // if(this.channelUUID === '474e7713-dbbf-46db-82c1-e2da15b58416') {
      // 	return false
      // }

      return true;
    },
    logoSrc () {
      return this.item.organization.logo.file.sources.original.replace(
        "http://default",
        "https://api.salescloud.is"
      );
    },
    organizationInitials () {
      const names = this.item.organization.label.split(" ");
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
    saleEnded () {
      if (
        this.systemTimeInMillis === null ||
        this.systemTimeInMillis === 0 ||
        this.item.end_sell_date === null ||
        this.item.end_sell_date === 0
      ) {
        return false;
      }
      return this.systemTimeInMillis > this.item.end_sell_date * 1000;
    },
    saleHasNotStarted () {
      if (
        this.systemTimeInMillis === null ||
        this.systemTimeInMillis === 0 ||
        this.item.start_sell_date === null ||
        this.item.start_sell_date === 0
      ) {
        return false;
      }
      return this.systemTimeInMillis < this.item.start_sell_date * 1000;
    },
    saleNotAvailable () {
      return this.saleEnded || this.saleHasNotStarted || this.isSoldOut;
    },
    primaryColor() {
      return "primary";
    },
  },
  /**
   * @property newAnonymousSession
   * @property session_token
   * @property uuid
   * @property entangleKey
   * @property minimumOrderQuantity
   * @property maximumOrderQuantity
   */
  methods: {
    addUpsellItem({ item, quantity }) {
      const itemIndex = this.selectedAdditionalItems.findIndex(
        (additionalItem) => additionalItem && additionalItem.uuid === item.uuid
      );

      // If we don't find the item currently we push it into the array
      if (itemIndex < 0) {
        if (quantity > 0) {
          this.selectedAdditionalItems.push({ ...item, quantity });
        } else {
          this.removeUpsellItem({ item, quantity });
        }
      } else {
        if (quantity > 0) {
          this.selectedAdditionalItems[itemIndex].quantity = quantity;
        } else {
          this.removeUpsellItem({ item, quantity });
        }
      }
    },
    removeUpsellItem({ item, quantity }) {
      const itemIndex = this.selectedAdditionalItems.findIndex(
        (additionalItem) => additionalItem && additionalItem.uuid === item.uuid
      );

      // We only want to do an action if we in fact find an index
      if (itemIndex >= 0) {
        const currentQuantity =
          this.selectedAdditionalItems[itemIndex].quantity;
        if (quantity === 0 || currentQuantity - quantity <= 0) {
          this.selectedAdditionalItems.splice(itemIndex, 1);
        } else {
          this.selectedAdditionalItems[itemIndex].quantity = quantity;
        }
      }
    },
    variationRequired() {
      return(this.variationOptions.length > 0 && this.selectedVariation === null);
    },
    fetchSessionToken(resolve) {
      const graphOptions = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const graphBody = {
        query: "mutation { newAnonymousSession { session_token, ttl } }",
      };

      axios
        .post(this.graphUrl, graphBody, graphOptions)
        .then(
          function (response) {
            this.sessionToken = response.data.data.newAnonymousSession;
          }.bind(this)
        )
        .catch(function () {}.bind(this))
        .finally(
          function () {
            resolve();
          }.bind(this)
        );
    },
    queueToCart() {
      this.addingToCart = true;

      new Promise((resolve) => {
        if (this.sessionToken === null) {
          this.fetchSessionToken(resolve);
        } else {
          resolve();
        }
      }).then(() => {
        const graphBody = {
          query:
            'mutation { queueToCart(channel: "' +
            this.channelUUID +
            '", item: "' +
            this.itemUUID +
            '", quantity: "' +
            this.quantity +
            '", sessionToken: "' +
            this.sessionToken.session_token +
            '", options: [' +
            this.selectedVariation +
            "]) }",
        };

        const graphOptions = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        return axios
          .post(this.graphUrl, graphBody, graphOptions)
          .then(
            function (response) {
              let languageOptionLocal = "is";

              if (this.language) {
                languageOptionLocal = this.language;
              }

              if (response.data.data.queueToCart) {
                document.location =
                  "https://queue.salescloud.is/?uuid=" +
                  response.data.data.queueToCart +
                  languageOptionLocal;
              }
            }.bind(this)
          )
          .catch(function () {}.bind(this))
          .finally(
            function () {
              // this.addingToCart = false;
            }.bind(this)
          );
      });
    },
    addToCart() {
      const organizationUUID = this.item.organization.uuid;

      this.addingToCart = true;

      new Promise((resolve) => {
        if (this.sessionToken === null) {
          this.fetchSessionToken(resolve);
        } else {
          resolve();
        }
      }).then(() => {
        let languageOption = "is";

        if (this.language) {
          languageOption = this.language;
        }

        // let query = 'mutation { addToCart(channel: "' + this.channelUUID + '", item: "' + this.itemUUID + '", quantity: "' + this.quantity + '", sessionToken: "' + this.sessionToken.session_token + '", language: "' + languageOption + '"'
        //
        // if(this.selectedVariation !== null) {
        // 	query += ', options: ["' + this.selectedVariation + '"]'
        // }
        //
        // if(this.customAmount !== null && this.customAmount > 0) {
        // 	query += ', customAmount: ' + this.customAmount
        // }
        //
        // query += ') { created, channel, line_item_label, entangleKey } }'
        //
        const values = {};

        if (this.customAmount > 0) {
          values.amount = this.customAmount;
        }

        // Handling giftcard and coupon item types
        const cartItems = [];
        if (
          this.item.type === "giftcard" ||
          this.item.type === "coupon" ||
          this.item.type === "event"
        ) {
          for (
            let quantityIndex = 0;
            quantityIndex < this.quantity;
            quantityIndex++
          ) {
            const item = {
              item: this.itemUUID,
              quantity: 1,
            };
            if (this.selectedVariation) {
              item.variations = [this.selectedVariation];
            }

            if (Object.keys(values).length) {
              item.uiValues = jsesc(values, { json: true });
            }

            cartItems.push(item);
          }
        } else {
          const item = {
            item: this.itemUUID,
            quantity:
              typeof this.quantity === "number"
                ? this.quantity
                : parseFloat(this.quantity),
          };
          if (this.selectedVariation) {
            item.variations = [this.selectedVariation];
          }
          if (Object.keys(values).length) {
            item.uiValues = jsesc(values, { json: true });
          }

          cartItems.push(item);
        }

        // Handling upsell items
        if (
          Array.isArray(this.selectedAdditionalItems) &&
          this.selectedAdditionalItems.length > 0
        ) {
          for (
            let itemIndex = 0;
            itemIndex < this.selectedAdditionalItems.length;
            itemIndex++
          ) {
            const additionalItem = this.selectedAdditionalItems[itemIndex];
            if (
              additionalItem.type === "giftcard" ||
              additionalItem.type === "coupon"
            ) {
              for (
                let itemQuantityIndex = 0;
                itemQuantityIndex < additionalItem.quantity;
                itemQuantityIndex++
              ) {
                cartItems.push({
                  item: additionalItem.uuid,
                  quantity: 1,
                });
              }
            } else {
              cartItems.push({
                item: additionalItem.uuid,
                quantity: additionalItem.quantity,
              });
            }
          }
        }

        const body = {
          query: `mutation AddMultipleItemsToCart($sessionToken: String!, $channel: String!, $cartItems: [CartItem], $language: String) {\n
                addMultipleItemsToCart(sessionToken: $sessionToken, channel: $channel, items: $cartItems, language: $language) {\n
                  created\n
                  channel {\n
                    uuid\n
                  }\n
                  lineItems {\n
                    uuid\n
                    line_item_label\n
                  }\n
                  entangleKey\n
                  uuid\n
                  state\n
                  orderTotal {\n
                      amount\n
                      currency_code\n
                  }\n
              }\n
            }`,
          variables: {
            sessionToken: this.sessionToken ? this.sessionToken.session_token : null,
            channel: this.channelUUID,
            cartItems,
            language: languageOption,
          },
        };

        // Removed by request because it broke everything, fokking hell
        // if(Array.isArray(this.selectedTimeSlots) && this.selectedTimeSlots.length > 0) {
        //   const slots = []
        //   // We use a for-loop rather than a map because we do not want the chance of map introducing null values
        //   for(let slotIndex = 0; slotIndex < this.selectedTimeSlots.length; slotIndex++) {
        //     let startsAtTime = this.selectedTimeSlots[slotIndex].startTimeInMillis
        //     let endsAtTime = this.selectedTimeSlots[slotIndex].startTimeInMillis
        //     if(startsAtTime && startsAtTime.toString().length === 13) {
        //       startsAtTime = startsAtTime / 1000
        //     }
        //     if(endsAtTime && endsAtTime.toString().length === 13) {
        //       endsAtTime = endsAtTime / 1000
        //     }
        //     slots.push({
        //       startsAtTime,
        //       endsAtTime
        //     })
        //   }
        //   const uiValues = {}
        //   uiValues.timeSlots = slots
        //   query += ', uiValues: ' + jsesc(uiValues, { 'json': true })
        // }

        // const graphBody = {
        // 	query: query
        // };
        //
        // const graphOptions = {
        // 	headers: {
        // 		'Content-Type': 'application/json'
        // 	}
        // };

        return axios
          .post(this.graphUrl, body)
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.data &&
              response.data.data.addMultipleItemsToCart
            ) {
              const addedToCart = response.data.data.addMultipleItemsToCart;
              const { session_token } = this.sessionToken;

              if (addedToCart) {
                let languageOptionInt = "is";

                if (this.language) {
                  languageOptionInt = this.language;
                }

                const channel =
                  addedToCart.channel && addedToCart.channel.uuid
                    ? addedToCart.channel.uuid
                    : this.channelUUID;

                if (this.useNewCheckout) {
                  const entangleKey = addedToCart.entangleKey;
                  const orderUUID = entangleKey.split(":")[0];
                  try {
                    window.parent.postMessage("closeResizer", "*");
                  } catch (e) {
                    // Do nothing
                  }

                  const isDark = this.$vuetify.theme.dark;
                  const darkValue = isDark === true ? "true" : "false";

                  document.location =
                    "https://checkout.salescloud.is/?time=" +
                    Date.now() +
                    "&dark=" +
                    darkValue +
                    "#" +
                    organizationUUID +
                    "/" +
                    channel +
                    "/" +
                    orderUUID +
                    "/checkout" +
                    "?sessionToken=" +
                    session_token +
                    "&language=" +
                    languageOptionInt;
                } else {
                  document.location =
                    "https://widgets.salescloud.is/channels/" +
                    channel +
                    "/checkout" +
                    "?session_token=" +
                    session_token +
                    "&language=" +
                    languageOptionInt +
                    "&timestamp=" +
                    Date.now();
                }
              }
            } else if (response.data.errors) {
              let errorMessage = "";
              try {
                errorMessage = response.data.errors[0];
              } catch (e) {
                errorMessage.message =
                  "Sorry. Some unknown error just happened.";
              }

              this.item = null;
              this.message = errorMessage.message;
              this.subMessage = errorMessage.alternateMessage;
            }
          })
          .catch((err) => {
            this.item = null;
            this.message = err.message;
          })
          .finally(() => {});
      });
    },
    fetchItem(uuid, organization) {
      this.loading = true;
      this.message = this.$t("message.fetchingItem");

      const graphOptions = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const graphBody = {
        query:
          "query {\n" +
          (organization
            ? ' organizationTranslationsI18nFormatted(organization:"' +
              organization +
              '"),'
            : "\n") +
          ' item(uuid: "' +
          uuid +
          '"' +
          (organization ? `, organization: "${organization}"` : "") +
          ") {\n" +
          "   uuid\n" +
          "   title\n" +
          "   type\n" +
          "   status\n" +
          "   payWhatYouWant\n" +
          "   description {\n" +
          "     value\n" +
          "   }\n" +
          "   price {\n" +
          "     amount\n" +
          "     currency_code\n" +
          "     taxRate {\n" +
          "       rate\n" +
          "     }\n" +
          "     data {\n" +
          "       include_tax\n" +
          "     }\n" +
          "   }\n" +
          "   event_guest_price {\n" +
          "     amount\n" +
          "     currency_code\n" +
          "   }\n" +
          "   sale_price {\n" +
          "     amount\n" +
          "     currency_code\n" +
          "   }\n" +
          "   images {\n" +
          "     file {\n" +
          "       sources {\n" +
          "         original\n" +
          "       }\n" +
          "     }\n" +
          "   }\n" +
          "   availability {\n" +
          "     current\n" +
          "     target\n" +
          "   }\n" +
          "   organization {\n" +
          "     label\n" +
          "     uuid\n" +
          "     primary_color\n" +
          "     logo {\n" +
          "       file {\n" +
          "         sources {\n" +
          "           original\n" +
          "         }\n" +
          "       }\n" +
          "     }\n" +
          "   }\n" +
          "   unitOfMeasure\n" +
          "   maximumOrderQuantity\n" +
          "   minimumOrderQuantity\n" +
          "   start_date\n" +
          "   end_date\n" +
          "   start_sell_date\n" +
          "   end_sell_date\n" +
          "   startDate\n" +
          "   endDate\n" +
          "   unavailableDates\n" +
          "   dates\n" +
          "   additionalItems {\n" +
          "     uuid\n" +
          "     title\n" +
          "     type\n" +
          "     status\n" +
          "     description {\n" +
          "       value\n" +
          "     }\n" +
          "     price {\n" +
          "       amount\n" +
          "       currency_code\n" +
          "     }\n" +
          "     sale_price {\n" +
          "       amount\n" +
          "       currency_code\n" +
          "     }\n" +
          "     exclude_discounts\n" +
          "     defaultQuantity\n" +
          "     minimumOrderQuantity\n" +
          "     maximumOrderQuantity\n" +
          "     maximumGroupedQuantity\n" +
          "   }\n" +
          " }\n" +
          ' variationOptions(uuid: "' +
          uuid +
          '"' +
          (organization ? `, organization: "${organization}"` : "") +
          ") {\n" +
          "   label\n" +
          "   uuid\n" +
	      "   price {\n" +
	      "     amount\n" +
	      "     currency_code\n" +
	      "   }\n" +
          " }\n" +
          " systemTimeInMillis\n" +
          "}",
      };

      axios
        .post(this.graphUrl, graphBody, graphOptions)
        .then(
          function (response) {
            if (response.data.data.item) {
              this.organization = response.data.data.item.organization;

              this.variationOptions = response.data.data.variationOptions;

              if (response.data.data.item.status) {
                this.item = response.data.data.item;

                if (this.item.payWhatYouWant) {
                  this.customAmount = this.item.price.amount;
                }

                if (response.data.data.item.organization) {
                  EventBus.$emit(
                    "organization",
                    response.data.data.item.organization
                  );
                }

                this.systemTimeInMillis = response.data.data.systemTimeInMillis;

                if (
                  this.item.minimumOrderQuantity &&
                  this.item.maximumOrderQuantity &&
                  !this.useQuantityRangeFromParams
                ) {
                  this.quantityRange = [];
                  const minimum = parseFloat(this.item.minimumOrderQuantity);
                  const maximum = parseFloat(this.item.maximumOrderQuantity);
                  for (let i = minimum; i <= maximum; i++) {
                    this.quantityRange.push(i);
                  }
                }
                if (
                  Array.isArray(this.quantityRange) &&
                  this.quantityRange.length > 0
                ) {
                  this.quantity = this.quantityRange[0];
                }

                const priceTaxRateExists =
                  this.item.price &&
                  typeof this.item.price.taxRate !== "undefined" &&
                  this.item.price.taxRate !== null;

                const dataIncludeTaxExists =
                  this.item.price &&
                  typeof this.item.price.data !== "undefined" &&
                  this.item.price.data !== null &&
                  typeof this.item.price.data.include_tax !== "undefined" &&
                  this.item.price.data.include_tax !== null &&
                  this.item.price.data.include_tax !== "";

                if (!priceTaxRateExists && !dataIncludeTaxExists) {
                  this.showVat = false;
                }

                if (
                  this.showVat &&
                  priceTaxRateExists &&
                  this.item.price.taxRate.rate <= 0
                ) {
                  this.showVat = false;
                }

                if (this.showVat && dataIncludeTaxExists) {
                  this.showVat =
                    !this.item.price.data.include_tax.includes("_0_");
                }
              } else {
                this.message = this.$t("message.itemNotForSale");
                this.subMessage = this.$t("subMessage.itemNotForSale");
              }
            } else {
              this.message = this.$t("message.itemDoesNotExist");
              this.subMessage = this.$t("subMessage.itemDoesNotExist");
            }

            if (
              response.data.data.organizationTranslationsI18nFormatted !==
                null &&
              response.data.data.organizationTranslationsI18nFormatted !==
                "undefined"
            ) {
              const keys = Object.keys(
                response.data.data.organizationTranslationsI18nFormatted
              );
              for (let i = 0; i < keys.length; i++) {
                i18n.mergeLocaleMessage(
                  keys[i],
                  response.data.data.organizationTranslationsI18nFormatted[
                    keys[i]
                  ]
                );
              }
            }
          }.bind(this)
        )
        .catch(
          function () {
            this.message = this.$t("message.fetchingError");
            this.subMessage = this.$t("subMessage.fetchingError");
          }.bind(this)
        )
        .finally(
          function () {
            this.loading = false;
          }.bind(this)
        );
    },
    hexToLuma(colour) {
      if (colour === null || typeof colour === "undefined") {
        return colour;
      }
      const hex = colour.replace(/#/, "");
      const red = parseInt(hex.substr(0, 2), 16);
      const green = parseInt(hex.substr(2, 2), 16);
      const blue = parseInt(hex.substr(4, 2), 16);

      return [0.299 * red, 0.587 * green, 0.114 * blue].reduce((a, d) => a + d) / 255;
    },
    colorIsLight(colour) {
      return 1 - this.hexToLuma(colour) < 0.47;
    },
    translateByType: (typeString, object) => {
      if (
        typeString === null ||
        typeString === undefined ||
        object === null ||
        object === undefined
      ) {
        return "";
      }
      const arr = typeString.split(".");
      let string = "";
      arr.forEach((str) => { string += `${str}:`; });
      if (i18n.te(string + object.uuid)) {
        return i18n.t(string + object.uuid);
      } else {
        if (arr.length === 1) {
          return object;
        } else if (arr.length === 2) {
          return object[arr[1]];
        } else if (arr.length === 3) {
          return object[arr[1]][arr[2]];
        } else if (arr.length === 4) {
          return object[arr[1]][arr[2]][arr[3]];
        }
        return "Check translation error.";
      }
    },
  },
  watch: {
    itemUUID(newUUID) {
      if (newUUID !== null) {
        this.fetchItem(newUUID, this.organizationUUID);
      }
    },
    item(newItem) {
      if (typeof newItem !== "undefined" && newItem !== null) {
        if (
          typeof newItem.organization !== "undefined" &&
          newItem.organization !== null
        ) {
          if (
            typeof newItem.organization.primary_color !== "undefined" &&
            newItem.organization.primary_color !== null
          ) {
            this.$vuetify.theme.themes.light.primary =
              newItem.organization.primary_color;
          }
        }
        const urlParams = new URLSearchParams(window.location.search);
        const amount = Number(urlParams.get("amount"));
        if (amount >= 0) {
          this.customAmount = amount;
        }
      }

      if (
        this.customPrimaryColor !== null &&
        this.customPrimaryColor !== undefined &&
        this.customPrimaryColor !== ""
      ) {
        this.$vuetify.theme.themes.light.primary = this.customPrimaryColor;
      }

      if (newItem && newItem.type === "event") {
        if (!Array.isArray(newItem.dates)) {
          if (newItem.startDate && newItem.endDate) {
            this.selectedTimeSlots = [
              {
                startTimeInMillis: newItem.startDate,
                endTimeInMillis: newItem.endDate,
              },
            ];
          }
          if (newItem.start_date && newItem.end_date) {
            this.selectedTimeSlots = [
              {
                startTimeInMillis: newItem.start_date,
                endTimeInMillis: newItem.end_date,
              },
            ];
          }
        }
      }
    },
  },
  mounted() {
    this.loading = true;

    const urlParams = new URLSearchParams(window.location.search);
    let itemUUID = null;
    let channelUUID = null;
    this.organizationUUID = urlParams.get("organization");

    if (urlParams.get("uuid")) {
      itemUUID = urlParams.get("uuid");
    }

    if (urlParams.get("channel")) {
      channelUUID = urlParams.get("channel");
    }

    if (urlParams.get("category")) {
      this.categoryUUID = urlParams.get("category");
    }

    const languageParam = urlParams.get("lang");

    if (languageParam && this.supportedLanguages.includes(languageParam)) {
      this.language = languageParam;
    }

    if (urlParams.get("hideImage") === "true") {
      this.hideImage = true;
    }

    if (urlParams.get("allowQuantitySelection") === "true") {
      this.allowQuantitySelection = true;
    }

    if (urlParams.get("miq") && urlParams.get("maq")) {
      const minQuantity = urlParams.get("miq");
      const maxQuantity = urlParams.get("maq");
      this.quantityRange = [];
      for (let p = minQuantity; p <= maxQuantity; p++) {
        this.quantityRange.push(parseInt(p));
      }
      this.useQuantityRangeFromParams = true;
    }

    const showUpsell = urlParams.get("showUpsell");
    if (typeof showUpsell !== "undefined" && showUpsell !== null) {
      this.showUpsell = showUpsell === true;
    }

    if (itemUUID !== null && channelUUID !== null) {
      this.channelUUID = channelUUID;
      this.itemUUID = itemUUID;
      return;
    }

    this.loading = false;
    this.message = this.$t("message.noItemReference");
    this.subMessage = this.$t("subMessage.noItemReference");
  },
};
</script>
