<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row
      v-if="upsellAmountFollowsItemQuantity"
      align="center"
      dense
    >
      <v-col
        cols="auto"
        class="grow"
      >
        <div style="font-size: 16px; font-weight: 500;">
          {{ `${upsellItem.title}` }}
        </div>
        <div>
          {{ `${upsellTotalItemAmount} (${quantity}x ${upsellUnitItemAmount})` }}
        </div>
      </v-col>
      <v-col
        cols="auto"
        class="shrink"
      >
        <v-switch
          v-model="toggleUpsellQuantity"
          color="success"
        />
      </v-col>
    </v-row>
    <v-row
      v-else
      dense
      align="center"
      class="mb-4"
    >
      <v-col
        cols="12"
        style="font-size: 16px; font-weight: 500;"
      >
        {{ upsellItem.title }}
      </v-col>
      <v-col
        cols="auto"
        class="grow"
      >
        <v-select
          hide-details
          outlined
          :items="upsellItemQuantities"
          v-model="upsellQuantity"
          dense
        />
      </v-col>
      <v-col
        cols="auto"
        class="shrink"
      >
        <v-btn
          class="py-5"
          elevation="0"
          small
          :disabled="!canDecrease"
          color="white"
          @click="decreaseQuantity"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="shrink"
      >
        <v-btn
          class="py-5"
          elevation="0"
          small
          :disabled="!canIncrease"
          color="white"
          @click="increaseQuantity"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UpsellItem",
  props: {
    upsellItem: {
      type: Object,
      default: () => ({})
    },
    quantity: {
      type: Number,
      default: 1
    },
    selectedAdditionalItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      on: false
    }
  },
  computed: {
    upsellAmountFollowsItemQuantity() {
      return false
    },
    upsellTotalItemAmount() {
      return this.upsellItemAmount(this.quantity)
    },
    upsellUnitItemAmount() {
      return this.upsellItemAmount(1)
    },
    canDecrease() {
      const firstQuantity = this.upsellItemQuantities[0]
      return this.upsellQuantity > firstQuantity
    },
    canIncrease() {
      const lastQuantity = this.upsellItemQuantities[this.upsellItemQuantities.length-1]
      return this.upsellQuantity < lastQuantity
    },
    upsellItemQuantities() {
      let min = 0
      let max = 10

      if(typeof this.upsellItem.minimumOrderQuantity !== 'undefined') {
        min = this.upsellItem.minimumOrderQuantity
      }

      if(typeof this.upsellItem.maximumOrderQuantity !== 'undefined') {
        if(this.upsellItem.maximumOrderQuantity >= min) {
          max = this.upsellItem.maximumOrderQuantity
        }
      }

      return Array.from({ length: (max + 1) - min}, (_,i) => min + i)
    },
    toggleUpsellQuantity: {
      get() {
        if(Array.isArray(this.selectedAdditionalItems) && this.selectedAdditionalItems.length > 0) {
          return this.selectedAdditionalItems.some(item => item && item.uuid === this.upsellItem.uuid)
        }
        return false
      },
      set(value) {
        if(value === true) {
          this.$emit('addUpsellItem', { item: this.upsellItem, quantity: this.quantity })
        }
        if(value !== true) {
          this.$emit('removeUpsellItem', { item: this.upsellItem, quantity: this.quantity })
        }
      }
    },
    upsellQuantity: {
      get() {
        if(Array.isArray(this.selectedAdditionalItems) && this.selectedAdditionalItems.length > 0) {
          const foundItem = this.selectedAdditionalItems.find(item => item && item.uuid === this.upsellItem.uuid)
          if(foundItem) {
            return foundItem.quantity
          }
        }
        return this.upsellItemQuantities[0]
      },
      set(value) {
        this.$emit('addUpsellItem', { item: this.upsellItem, quantity: value })
      }
    }
  },
  methods: {
    increaseQuantity() {
      this.upsellQuantity++
    },
    decreaseQuantity() {
      this.upsellQuantity--
    },
    upsellItemAmount(quantity) {
      let amount = 0
      let currency = 'ISK'

      if(typeof this.upsellItem !== 'undefined' && this.upsellItem !== null) {
        if(typeof this.upsellItem.sale_price !== 'undefined' && this.upsellItem.sale_price !== null && typeof this.upsellItem.sale_price.amount !== 'undefined' && this.upsellItem.sale_price.amount > 0) {
          amount = this.upsellItem.sale_price.amount
          currency = this.upsellItem.sale_price.currency_code
        } else if(typeof this.upsellItem.price !== 'undefined' && this.upsellItem.price !== null && typeof this.upsellItem.price.amount !== 'undefined' && this.upsellItem.price.amount > 0) {
          amount = this.upsellItem.price.amount
          currency = this.upsellItem.price.currency_code
        }
      }

      amount = amount * quantity

      if(currency === 'ISK') {
        return `${amount} kr.`
      }
      const formatter = Intl.NumberFormat(currency, { style: 'currency', currency: currency })
      const amountToDisplay = amount/100
      return formatter.format(amountToDisplay)
    },
  }
}
</script>

<style scoped>

</style>
