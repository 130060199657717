import 'babel-polyfill'
import 'url-search-params-polyfill'
// skipcq: JS-C1003
import * as Resize from './iframeResizer.contentWindow.min.js'
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import App from './App.vue'
import Vuetify from 'vuetify/lib'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import i18n from './i18n'

import { version } from '../package.json'

Vue.use(Resize)

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  appVersion: version,
  plugins: [
      new BugsnagPluginVue()
  ]
})

// skipcq: JS-0002
console.log(`SalesCloud Item version: ${version}`)

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)

Vue.use(VueAnalytics, {
  id: 'UA-53948108-7',
  autoTracking: {
    screenview: true
  }
})

Vue.filter('toCurrency', (price) => {

  let minimumDigits = 0
  let amount = price.amount
  const divider = 100

  if(price.currency_code !== 'ISK') {
    minimumDigits = 2
    amount = amount / divider
  }

  const formatter = new Intl.NumberFormat(['fr-FR', 'is-IS'], {
    style: 'currency',
    currency: price.currency_code,
    minimumFractionDigits: minimumDigits
  })

  return formatter.format(amount);
})

const vuetifyOptions = { }

Vue.use(Vuetify)
Vue.config.productionTip = false

new Vue({
  vuetify: new Vuetify(vuetifyOptions),
  i18n,
  render: h => h(App)
}).$mount('#app')
